import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import useConfig from 'hooks/useConfig';
import { flatten } from 'flat';
import { merge } from 'lodash-es';
import { getLocaleConfig } from 'utils/date/getLocale';

const loadLocaleData = (i18n) => {
  switch (i18n) {
    case 'tr':
      return [
        import('utils/locales/tr/action.json'),
        import('utils/locales/tr/admin.json'),
        import('utils/locales/tr/contract.json'),
        import('utils/locales/tr/controlPoint.json'),
        import('utils/locales/tr/coupon.json'),
        import('utils/locales/tr/default.json'),
        import('utils/locales/tr/event.json'),
        import('utils/locales/tr/faq.json'),
        import('utils/locales/tr/input.json'),
        import('utils/locales/tr/invitation.json'),
        import('utils/locales/tr/licenses.json'),
        import('utils/locales/tr/notification.json'),
        import('utils/locales/tr/order.json'),
        import('utils/locales/tr/organiser.json'),
        import('utils/locales/tr/package_.json'),
        import('utils/locales/tr/participant.json'),
        import('utils/locales/tr/payment.json'),
        import('utils/locales/tr/paymentMethod.json'),
        import('utils/locales/tr/program.json'),
        import('utils/locales/tr/promotion.json'),
        import('utils/locales/tr/question.json'),
        import('utils/locales/tr/speaker.json'),
        import('utils/locales/tr/sponsor.json'),
        import('utils/locales/tr/tag.json'),
        import('utils/locales/tr/ticket.json'),
        import('utils/locales/tr/topic.json'),
        import('utils/locales/tr/validation.json'),
        import('utils/locales/tr/widgets.json'),
      ];
    default:
      return [
        import('utils/locales/en/action.json'),
        import('utils/locales/en/admin.json'),
        import('utils/locales/en/contract.json'),
        import('utils/locales/en/controlPoint.json'),
        import('utils/locales/en/coupon.json'),
        import('utils/locales/en/default.json'),
        import('utils/locales/en/event.json'),
        import('utils/locales/en/faq.json'),
        import('utils/locales/en/input.json'),
        import('utils/locales/en/invitation.json'),
        import('utils/locales/en/licenses.json'),
        import('utils/locales/en/notification.json'),
        import('utils/locales/en/order.json'),
        import('utils/locales/en/organiser.json'),
        import('utils/locales/en/package_.json'),
        import('utils/locales/en/participant.json'),
        import('utils/locales/en/payment.json'),
        import('utils/locales/en/paymentMethod.json'),
        import('utils/locales/en/program.json'),
        import('utils/locales/en/promotion.json'),
        import('utils/locales/en/question.json'),
        import('utils/locales/en/speaker.json'),
        import('utils/locales/en/sponsor.json'),
        import('utils/locales/en/tag.json'),
        import('utils/locales/en/ticket.json'),
        import('utils/locales/en/topic.json'),
        import('utils/locales/en/validation.json'),
        import('utils/locales/en/widgets.json'),
      ];
  }
};

const Locales = ({ children }) => {
  const { i18n } = useConfig();
  const [messages, setMessages] = useState();

  useEffect(() => {
    setMessages();
    Promise.all(loadLocaleData(i18n)).then((ds) => {
      const messages_ = {};
      Object.assign(messages_, merge(...ds.map((d) => d.default)));
      setMessages(messages_);
    });
  }, [i18n]);

  return (
    <>
      {messages && (
        <IntlProvider
          locale={i18n}
          defaultLocale={getLocaleConfig()}
          messages={flatten(messages)}
        >
          {children}
        </IntlProvider>
      )}
    </>
  );
};

Locales.propTypes = {
  children: PropTypes.node,
};

export default Locales;
